import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Webcam from '@uppy/webcam';
import XHRUpload from '@uppy/xhr-upload';
import French from '@uppy/locales/lib/fr_FR';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

document.querySelectorAll('.upload-DragDrop').forEach(function(element){
    f_initUppy(element);
});

function f_initUppy(element) {
    var medias = [];

    var uppy = new Uppy({
        debug: false,
        autoProceed: true,
        locale: French,
        restrictions: {
            maxFileSize: 10000000,
            maxNumberOfFiles: element.dataset.maxnbruploadfile,
            allowedFileTypes: ['image/*', 'application/pdf']
        },
        meta: {
            _token: element.dataset.token
        }
    });

    uppy.use(
        Dashboard, {
            inline: true,
            target: element,
            replaceTargetContent: true,
            showLinkToFileUploadResult: false,
            showProgressDetails: true,
            showRemoveButtonAfterComplete: true,
            doneButtonHandler: null,
            note: element.dataset.note,
            height: 470,
            width: '100%',
            browserBackButtonClose: true,
            proudlyDisplayPoweredByUppy: false
        });

    uppy.use(
        Webcam, {
            countdown: false,
            target: Uppy.Dashboard,
            modes: [
                'picture'
            ],
            mirror: true,
            facingMode: 'environment',
            showRecordingLength: false,
            preferredVideoMimeType: null,
            preferredImageMimeType: null,
            locale: {}
        });

    uppy.use(XHRUpload, {
        endpoint: element.dataset.endpoint + '?champ_nom=' + element.dataset.name,
        formData: true,
        fieldName: 'media',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    });

    uppy.on('upload-success', function (file, response) {
        medias.push(response.body.media);
    });
    uppy.on('upload-error', function (file, error, response) {
        console.log('error with file:', file.id);
        console.log('error message:', error);
        console.log('error message:', response);

        if (response.status !== 200 && response.body.message !== undefined) {
            response.body.message.forEach(function (error) {
                element.parentElement.querySelector('.upload-alerts').insertAdjacentHTML('beforeend', '<div class="alert alert-warning">' + file.name +' : ' + error +'</div>');
            })
        }
    });
    uppy.on('file-removed', function (file) {
        medias.forEach(function(media, index, medias){
            if (media.nom === file.name) {
                medias.splice(index, 1);

                $.ajax({
                    url: element.dataset.destroyroute + '?file=' + media.tmp_file
                });

                return false;
            }
        });
    });
    uppy.on('restriction-failed', function (file, error) {
        /*document.querySelector('#upload-alerts').insertAdjacentHTML('beforeend', '<div class="alert alert-warning">' + file.name +' : ' + error +'</div>')*/
    });

    if(element.dataset.medias !== 'null') {
        JSON.parse(element.dataset.medias).forEach(function(media) {
            if(media.champ_nom === element.dataset.name) {
                fetch(element.dataset.showroute + '?tmp=' + media.tmp_file + '&filename=' + media.nom)
                    .then((response) => response.blob())
                    .then((blob) => {
                        uppy.addFile({
                            name: media.nom,
                            type: blob.type,
                            data: blob
                        });
                    });
            }
        });
    }
}


const handleAlertEmail = () => {
    const emailInputWrapper = $('#alert-handle-email');
    const emailInput = $('#alert-email-input');
    emailInputWrapper.hide();
    $('#alert-email').on('click', () => {
        const isChecked = $('#alert-email').prop('checked');
        if (isChecked) {
            emailInputWrapper.show();
            emailInput.attr('required', true);
            $('#inpt-offre-comm').css("visibility", "visible");
        } else {
            emailInputWrapper.hide();
            emailInput.attr('required', false);
            $('#inpt-offre-comm').css("visibility", "hidden");
        }
    });
};

handleAlertEmail();

const handleAlertCommunesQuatiers = () => {
    $('#alerte-communes-quartiers').select2({
        ajax: {
            url: $('#alerte-communes-quartiers').data('ajax-url'),
            dataType: 'json',
        }
    });
};

handleAlertCommunesQuatiers();

var frmAlerte = document.getElementById('form-alerte');

if (frmAlerte) {

    var par_notification_box = document.getElementById('alert-push');
    var par_email_box = document.getElementById('alert-email');

    main();
}

/**
 * Initialise le formulaire
 */
function main() {
    if (!checkNotification() || Notification.permission === 'denied') {
        disabelNotification()
        return;
    }

    document.getElementById('btn_notify').addEventListener('click', (event) => submitForm(event))
}

/**
 * Soumission du formulaire
 *
 * @param event
 * @returns {Promise<void>}
 */
async function submitForm(event) {
    event.preventDefault();

    // Validation du formulaire
    const formValidate = await frmAlerte.reportValidity();
    if (!formValidate) {
        return;
    }
    if (!par_notification_box.checked && !par_email_box.checked) {
        flashNotification("Vous devez sélectionner un type d'alerte");
        return;
    }

    // Push Notification
    if (par_notification_box.checked) {

        try {
            var souscription = await askPermission();
            if (!souscription) {
                if (Notification.permission === 'denied') {
                    // Cas de refus de notification ou mode privé sur Chrome
                    flashNotification("Notification refusée");
                    disabelNotification();
                } else {
                    // Cas demande de notification fermé sans choix sur Chrome
                    //flashNotification("Erreur");
                }
                return;
            }
        } catch (e) {
            flashNotification("Votre appareil n'est pas compatible notification push");
            console.log(e.message);
            disabelNotification();
            return;
        }

        document.getElementById('souscription').value = JSON.stringify(souscription);
    }


    frmAlerte.submit();
}

/**
 * Demande / vérifie la permission "Notification"
 *
 * @returns {Promise<boolean|PushSubscription>}
 */
async function askPermission() {
    var permission = await Notification.requestPermission();
    if (permission === 'granted') {
        return await registerServiceWorker();
    } else return false;

}

/**
 * Enregistre l'abonnement de push notification
 *
 * @returns {Promise<PushSubscription>}
 */
async function registerServiceWorker() {
    const registration = await navigator.serviceWorker.register(new URL('/theme/javascript/sw.js', import.meta.url));

    // Pour Chrome Android a cause du message ci-dessous à la première initialisation du service worker
    // "Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker"
    await registration.update();

    var subscription = await registration.pushManager.getSubscription();

    if (!subscription) {
        subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(document.getElementById('type_alerte_push').dataset.applicationServerKey)
        });
    }

    return subscription;
}

function disabelNotification() {
    $('#alert-push').prop('disabled', true);
    $('.alert-push').hide();
    $('#alert-push').hide();
    /*document.getElementById('type_alerte_push').style.display = 'none';
    par_email_box.style.display = 'none';
    par_email_box.checked = true;
    par_notification_box.checked = false;
    document.getElementById('email-form').className = '';*/
}


/**
 * Affiche un message d'erreur dans la page
 * @param message
 */
function flashNotification(message) {
    notification = document.getElementById('flash-notification');
    notification.textContent = message;
    notification.style.display = 'block';
}


function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


/**
 * Vérification compatibilité push notification
 * Ne prend compte que la compatibilité basique
 * @returns {boolean}
 */
function checkNotification() {
    return !(!('Notification' in window) || !('serviceWorker' in navigator));
}


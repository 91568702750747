import SignaturePad from "signature_pad";

const handleSignature = () => {
    if( $('#form-signature').length ) {
        const canvas = document.getElementById('form-signature');
        const signaturePad = new SignaturePad(canvas, {
            backgroundColor: 'rgb(255, 255, 255)'
        });

        $('#clear').on('click', () => {
            signaturePad.clear();
        });

        $('#validate').on('click', () => {
            if (!signaturePad.isEmpty()) {
                $('#signature').val(signaturePad.toDataURL('image/jpeg'));
            }
        });
    }
};

handleSignature();

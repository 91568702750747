const faqClose = () => {
  $('.faq-item--content').hide();
  $('.faq-item').removeClass('open');
};

const faqToggle = () => {
  faqClose();
  $('.faq-item--button').on('click', (e) => {
    const { currentTarget } = e;
    const dataTarget = currentTarget.getAttribute('data-faq');
    const isOpen = $(`#${dataTarget}`).hasClass('open');
    faqClose();
    if (!isOpen) {
      $(`#${currentTarget.getAttribute('data-faq')} .faq-item--content`).toggle();
      $(`#${dataTarget}`).addClass('open');
    }
  });
};

const faqSort = () => {
    faqClose();
    $('#categorie-faq').on('change', function() {
        if ($(this).val() !== '0') {
            const selectedCategorie = $(this).val();
            $('.faq-question').hide();
            $('.faq-question').each(function() {
                const question = $(this).attr('data-categorie').split(',');
                if( question.includes(selectedCategorie) ) {
                    $(this).show();
                }
            });
        } else {
            $('.faq-question').show();
        }
    });
};

faqSort();

faqToggle();

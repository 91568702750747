import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-fr';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';

const lang = $('body').attr('data-lang');

$('.datepicker').datepicker({
  regional: lang,
  showOn: 'both',
  buttonText: '',
  buttonImage: '/theme/styles/assets/icons/calendar_today.png',
  buttonImageOnly: false,
  numberOfMonths: 1,
});

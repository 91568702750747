import { Loader } from 'google-maps';

if ($('#gmap-multiple').length || $('#gmap-zapay').length || $('#gmap-location').length) {


    const loader = new Loader('my-api-key', {
        key: 'AIzaSyAMMULY-jHktkj7ODjcjdR45Dy2GT1_dgI',
    });


    loader.load().then((google) => {
        const gmapMultiple = () => {
            const gmapMuliple = document.getElementById('gmap-multiple');
            const map = new google.maps.Map(gmapMuliple, {
                zoom: $('#gmap-multiple').data('zoom'),
                center: {
                    lat: $('#gmap-multiple').data('lat'),
                    lng: $('#gmap-multiple').data('lng'),
                },
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [
                            { visibility: "off" }
                        ]
                    }]
            });

            const infoWin = new google.maps.InfoWindow();
            window.locations.forEach((location) => {
                const {
                    city, perturbations,
                } = location;

                const iconType = perturbations ? '/theme/styles/assets/icons/marker-orange.png' : '/theme/styles/assets/icons/marker-green.png';

                const marker = new google.maps.Marker({
                    position: location,
                    map,
                    icon: iconType,
                });

                var content = `<div style="padding: 0"><h3 style="margin: 0 0 5px 0; color: #004997; font-size: 14px; font-weight: bold">${city}</h3>`;

                if (perturbations) {
                    JSON.parse(perturbations).forEach(function(perturbation){
                        content += `
                            <p style="margin: 5px 0 0 0;"><a href="${perturbation.url}" target="_parent">${perturbation.quartiers}</a></p>
                            <p style="margin: 2px 0 10px 0; font-weight: bold; display: flex; align-items: center">
                                <img src="${iconType}" width="20" style="margin-right: 5px" alt=""/>
                                ${perturbation.statut}
                            </p>`;
                    });
                } else {
                    content += `
                        <p style="margin: 2px 0 10px 0; font-weight: bold; display: flex; align-items: center">
                            <img src="${iconType}" width="20" style="margin-right: 5px" alt=""/>
                            Aucune perturbation
                        </p>`;
                }
                content += `</div>`;

                google.maps.event.addListener(marker, 'click', () => {
                    infoWin.setContent(content);
                    infoWin.open(map, marker);
                });
                return marker;
            });
        };

        const gmapZapay = () => {
            const gmapMuliple = document.getElementById('gmap-zapay');
            const map = new google.maps.Map(gmapMuliple, {
                zoom: $('#gmap-zapay').data('zoom'),
                center: {
                    lat: $('#gmap-zapay').data('lat'),
                    lng: $('#gmap-zapay').data('lng'),
                },
            });

            const infoWin = new google.maps.InfoWindow();

            window.locations.forEach((location) => {
                const marker = new google.maps.Marker({
                    position: location,
                    map,
                    icon: 'theme/styles/assets/icons/marker-zapay.png',
                });

                const {
                    address, city, name, phone,
                } = location;

                google.maps.event.addListener(marker, 'click', () => {
                    infoWin.setContent(`<div class="pa-10"><h3 class="title-8 color-blue-dark">${name}</h3><p class="mt-5">${address}</p><p class="mt-5">${city}</p><p class="mt-5">${phone}</p></div>`);
                    infoWin.open(map, marker);
                });

                return marker;
            });
        };

        const gmapLocation = () => {
            const map = new google.maps.Map(document.getElementById('gmap-location'), {
                mapTypeId: 'roadmap',
                scrollwheel: false,
                panControl: false,
                zoomControl: true,
                center: {
                    lat: $('#gmap-location').data('lat'),
                    lng: $('#gmap-location').data('lng'),
                },
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                zoom: $('#gmap-location').data('zoom'),
            });

            let marker;

            const placeMarker = (location) => {
                if (marker) {
                    marker.setPosition(location);
                } else {
                    marker = new google.maps.Marker({
                        position: location,
                        map,
                        icon: '../theme/styles/assets/icons/marker-orange.png',
                    });
                }
            };

            if ($('#location-input').val()) {
                const latlng = new google.maps.LatLng($('#location-input').val().split(';')[0], $('#location-input').val().split(';')[1]);
                placeMarker(latlng);
            }

            google.maps.event.addListener(map, 'click', (event) => {
                const latLng = `${event.latLng.lat()};${event.latLng.lng()}`;
                placeMarker(event.latLng);
                $('#location-input').attr('value', latLng);
            });

            $('#handle-geolocation').on('click', (e) => {
                const { currentTarget } = e;
                const text = currentTarget.textContent;
                currentTarget.innerHTML = 'Chargement...';
                navigator.geolocation.getCurrentPosition(
                    ({ coords: { latitude, longitude } }) => {
                        currentTarget.innerHTML = text;
                        const latlng = new google.maps.LatLng(latitude, longitude);
                        map.setCenter(latlng);
                        placeMarker(latlng);
                        $('#location-input').attr('value', latitude + ';' + longitude);
                    },
                    () => {
                        alert('Veuillez activer la géolocalisation');
                        currentTarget.innerHTML = text;
                    },
                );
            });
        };

        if ($('#gmap-multiple').length) {
            gmapMultiple();
        }

        if ($('#gmap-zapay').length) {
            gmapZapay();
        }

        if ($('#gmap-location').length) {
            gmapLocation();
        }
    });

}

import select2 from 'select2';
import 'select2/dist/css/select2.css';

window.select2 = select2();

$('.selectpicker-faq').select2({
  minimumResultsForSearch: -1,
  placeholder: 'Filtrer',
  width: '300px',
});

$('.selectpicker-form-mandatory').select2({
  placeholder: 'Rechercher',
  width: '100%',
});

$('.selectpicker-form').select2({
  placeholder: 'Renseigner le champs',
  width: '100%',
});

$('.selectpicker-alert').select2({
  placeholder: 'Recherchez un(e) ou plusieurs villes/quartiers',
});

$('.form-overlay.activated').click(() => {
  $('html, body').animate({
    scrollTop: $('#selectpicker-form-mandatory').offset().top,
  }, 300);

  $('#selectpicker-form-mandatory').select2('open');
});


$('#handle-selectpicker-form-mandatory').on('click', () => {
  const val = $('#selectpicker-form-mandatory').val();
  /*$("#communes-form select").val(val).trigger('change');*/
  if (val !== '' && val !== undefined) {
    $('.form-overlay').removeClass('activated').off('click');
    document.querySelector('.form-overlay');

    $('html, body').animate({
      scrollTop: $('.form-overlay').offset().top,
    }, 300);
  }
});

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

$('#slick-home').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: false,
  infinite: true,
  arrows: true,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 200,
  centerMode: false,
  autoplaySpeed: 4000,
  appendArrows: '#slick-car-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-large-prev"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-large-next"></span></button>',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        autoplay: true,
      },
    },
  ],
});

setTimeout(function() {
  $('#slick-home .slick-active').addClass('animation');
}, 250);

$('#slick-home').on('afterChange', function() {
  $('#slick-home .slick-active').addClass('animation');
});

$('#slick-home').on('beforeChange', function() {
  $('#slick-home .slick-active').removeClass('animation');
});
